import React from "react";

import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section section--gradient">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="section">
                  <h3
                    className="title has-text-weight-semibold is-size-3 my-5 is-family-secondary"
                    style={{ color: "#6ba696" }}
                  >
                    Συχνές Ερωτήσεις
                  </h3>
                  <BlogRoll />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
